import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, StyleSheet } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';

// Import your components
import Homepage from './Homepage';
import CreateSubmissionPage from './CreateSubmissionPage';
import UserRecapPage from './UserRecapPage';
import UserCollabDashboard from './UserCollabDashboard';
import CollabCampaignDetailsPage from './CollabCampaignDetailsPage';
import UserGetAssignQRPage from './UserGetAssignQRPage';
import UserGetQRSearch from './UserGetQRSearch';
import QRCodeScannerPage from './QRCodeScannerPage';
import BrandURLLandingPage from './BrandURLLandingPage';
import MediaUpload from './MediaUpload';
import SignUpPage from './SignUpPage';
import LogInPage from './LogInPage';
import CollabCalendar from './CollabCalendar';
import BrandListings from './BrandListings';
import InfluencerInviteLink from './InfluencerInviteLink';
import ManualVisitRedeem from './ManualVisitRedeem';

const Stack = createStackNavigator();

const App = () => {
  const [username, setUsername] = useState(null); // Stores the logged-in username
  const [loading, setLoading] = useState(true); // Controls the loading spinner

  // Fetch session data on app start
  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const response = await fetch('https://collabbay.com/APIControllers/GetSessionData.php');
        const data = await response.json();
        setUsername(data.Username || null); // Set username if available
      } catch (error) {
        console.error('Error fetching session data:', error);
      } finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };

    fetchSessionData();
  }, []);

  if (loading) {
    // Display loading spinner while fetching session data
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#000" />
      </View>
    );
  }

  return (
    <Router>
      <Routes>
      <Route path="/SignUp/:Referrer" element={<SignUpPageWrapper />} />
        <Route path="/collab/:CollabID" element={<BrandURLLandingPage />} />
        <Route path="/collaboffer/:ListingID" element={<CreateSubmissionPageWrapper />} />
        <Route path="/invite/:InvitationID" element={<InfluencerInviteLinkWrapper />} />
        <Route path="/" element={<AppContent username={username} />} />
      </Routes>
    </Router>
  );
};

const AppContent = ({ username }) => {
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Homepage" screenOptions={{ headerShown: false }}>
        <Stack.Screen name="Homepage" component={Homepage} />
        {username && (
          <>
            <Stack.Screen name="CreateSubmissionPage" component={CreateSubmissionPage} />
            <Stack.Screen name="UserRecapPage" component={UserRecapPage} />
            <Stack.Screen name="UserCollabDashboard" component={UserCollabDashboard} />
            <Stack.Screen name="CollabCampaignDetailsPage" component={CollabCampaignDetailsPage} />
            <Stack.Screen name="UserGetAssignQRPage" component={UserGetAssignQRPage} />
            <Stack.Screen name="UserGetQRSearch" component={UserGetQRSearch} />
            <Stack.Screen name="QRCodeScannerPage" component={QRCodeScannerPage} />
            <Stack.Screen name="MediaUpload" component={MediaUpload} />
            <Stack.Screen name="CollabCalendar" component={CollabCalendar} />
            <Stack.Screen name="BrandListings" component={BrandListings} />
            <Stack.Screen name="InfluencerInviteLink" component={InfluencerInviteLink} />
            <Stack.Screen name="ManualVisitRedeem" component={ManualVisitRedeem} />
          </>
        )}
        <Stack.Screen name="LogInPage" component={LogInPage} />
        <Stack.Screen name="SignUpPage" component={SignUpPage} />
        <Stack.Screen name="BrandURLLandingPage" component={BrandURLLandingPage} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

// Wrapper for CreateSubmissionPage to pass route params
const CreateSubmissionPageWrapper = () => {
  const { ListingID } = useParams();
  const route = { params: { ListingID } };
  const navigation = {
    goBack: () => window.history.back(),
  };

  return <CreateSubmissionPage route={route} navigation={navigation} />;
};

const SignUpPageWrapper = () => {
  const { Referrer } = useParams();
  const route = { params: { Referrer } };
  const navigation = {
    goBack: () => window.history.back(),
  };

  return <SignUpPage route={route} navigation={navigation} />;
};


// Wrapper for InfluencerInviteLink to pass route params
const InfluencerInviteLinkWrapper = () => {
  const { InvitationID } = useParams();
  const route = { params: { InvitationID } };
  const navigation = {
    goBack: () => window.history.back(),
  };

  return <InfluencerInviteLink route={route} navigation={navigation} />;
};

// Loading spinner styles
const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
});

export default App;
