import React, { useEffect, useState } from 'react';
import { View, Text, ActivityIndicator, Image, ScrollView, TouchableOpacity, Linking } from 'react-native';
import { useParams } from 'react-router-dom';
import QRCode from 'react-native-qrcode-svg';
import { Ionicons } from '@expo/vector-icons';

const ViewTracker = () => {
  const [collabData, setCollabData] = useState(null);
  const [offerData, setOfferData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { CollabID } = useParams(); // Extract CollabID from URL
  const [viewTracked, setViewTracked] = useState(false); // Add state to track if view has been tracked

  useEffect(() => {
    const fetchCollabData = async () => {
      try {
        setLoading(true);
        // Fetch collab data
        const response = await fetch(`https://collabbay.com/APIControllers/RetrieveCollabLandingPage.php?CollabID=${CollabID}`);
        const data = await response.json();

        if (data) {
          setCollabData(data);
          setLoading(false);

          // Fetch offer data using ListingID
          fetchOfferData(data.ListingID);
        } else {
          console.error('No data found for CollabID:', CollabID);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching collab data:', error);
        setLoading(false);
      }
    };

    fetchCollabData(); // Fetch collab data
  }, [CollabID]);

  const fetchOfferData = async (ListingID) => {
    try {
      const response = await fetch(`https://collabbay.com/APIControllers/RetrieveOfferDescription.php?ListingID=${ListingID}`);
      const data = await response.json();

      if (data) {
        setOfferData(data);
      } else {
        console.error('No data found for ListingID:', ListingID);
      }
    } catch (error) {
      console.error('Error fetching offer data:', error);
    }
  };

  useEffect(() => {
    if (collabData && !viewTracked) {
      trackView(collabData);
    }
  }, [collabData, viewTracked]);

  const trackView = async (collabData) => {
    try {
      // Fetch IP address of the server where the PHP script is hosted
      const response = await fetch('https://collabbay.com/APIControllers/ViewTrackController.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          CollabID: collabData.CollabID,
          ListingID: collabData.ListingID,
          BrandName: collabData.BrandName,
          Promoter: collabData.Promoter,
          // Add other fields from collabData here
        }),
      });
      const responseData = await response.json();

      // Check if the tracking was successful
      if (responseData.success) {
        console.log('View tracked successfully');
        setViewTracked(true); // Set viewTracked to true after successful tracking
      } else {
        console.error('Failed to track view:', responseData.message);
      }
    } catch (error) {
      console.error('Error tracking view:', error);
    }
  };

  // Ensure the URL has the correct format
  const formatURL = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `https://${url}`;
    }
    return url;
  };

  return (
    <ScrollView contentContainerStyle={{ backgroundColor: 'white', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      {/* Display Logo */}
      <View style={{ position: 'absolute', top: 10, alignItems: 'center', marginBottom: 15 }}>
        <Image
          source={require('./CollabbayLogoDark.png')} // Use require to reference the image file
          style={{ width: 30, height: 30, marginTop: 5, marginBottom: 20, borderRadius: 15, resizeMode: 'cover'  }} // Adjust size as needed
        />
      </View>

      {loading ? (
        <ActivityIndicator size="large" color="#000000" />
      ) : (
        <>
          {collabData && (
            <>
              <View style={{
                width: '80%', // 80% of the screen's width
                justifyContent: 'center', // Center content vertically
                alignItems: 'center', // Center content horizontally
                padding: 20,
                borderRadius: 10,                
              }}>
                <Text style={{ color: 'black', fontSize: 30, textAlign: 'center', marginTop: 25 }}>{collabData.AdHeader}</Text>
                
              </View>

              {offerData && (
                <View style={{ alignItems: 'center', marginTop: 15 }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ color: 'black', fontSize: 23, fontWeight: 'bold', marginLeft: 10 }}>{offerData.BrandName}</Text>
                  </View>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Ionicons name="location-sharp" size={24} color="black" />
                    <Text style={{ color: 'black', fontSize: 11, marginLeft: 10 }}>{offerData.Location}</Text>
                  </View>
                 </View>
              )}

              {offerData && (
                <View style={{
                  marginTop: 10,
                  marginBottom: 10,
                  padding: 10,
                  borderRadius: 10,
                
                }}>
                  <Image
                    source={{ uri: offerData.LogoImage }}
                    style={{ width: 150, height: 150, borderRadius: 100 }}
                  />
                </View>
              )}

              {/* QR Code and Redeem Text */}
              {/* <Text style={{ textAlign: 'center', fontWeight: 'bold', color: 'black', fontSize: 11, marginTop: 10, backgroundColor: '#FDF05D', padding: 10, borderRadius: 10 }}>
                  Save and present QR at {collabData.BrandName} to confirm your visit.
                </Text> */}
              <View style={{ alignItems: 'center', marginTop: 10, marginBottom: 10 }}>
                {/* <QRCode
                  value={`https://collabbay.com?type=CustomerScan&CollabID=${CollabID}`}
                  size={200}
                  color="black" // Set QR code foreground to black
                  backgroundColor="white" // Set QR code background to white
                /> */}

                {offerData && (
                  <TouchableOpacity onPress={() => Linking.openURL(formatURL(offerData.BrandURL))}>
                    <Text style={{
                      color: 'black',
                      fontSize: 18,
                      fontWeight: 'bold', // Make text bold
                      marginTop: 15,
                      paddingTop: 10,
                      paddingBottom: 10,// Add top padding
                      borderRadius: 10, // Add rounded borders
                      paddingHorizontal: 10, // Add horizontal padding for better readability
                      textAlign: 'center',
                      backgroundColor: '#FDF05D', padding: 10, borderRadius: 10  // Center text horizontally
                    }}>
                      <Ionicons name="link" size={16} color="black" style={{
                        color: 'black',
                        marginRight: 10,
                        marginTop: 10,
                   
                      }} />
                      More about {offerData.BrandName}
                    </Text>
                  </TouchableOpacity>
                )}

                <Text style={{
                  color: 'black',
                  fontSize: 20,
                  fontWeight: 'bold', // Make text bold
                  marginTop: 15,
                  paddingTop: 10,
                  paddingBottom: 10,// Add top padding
                  backgroundColor: 'white', // Set background color to white
                  borderRadius: 10, // Add rounded borders
                  paddingHorizontal: 10,
                  marginBottom: 15  // Add horizontal padding for better readability
                }}>
                  {collabData.BrandName} X {collabData.Promoter}
                </Text>
              </View>
            </>
          )}
        </>
      )}

      {offerData && (
        <TouchableOpacity onPress={() => Linking.openURL(formatURL('https://collabbay.com'))}>
          <Text style={{
            fontWeight: 'bold',
            bottom: 5,
            color: 'black',
            textAlign: 'center',
          }}>
            collabbay.
          </Text>
          <Text style={{
            bottom: 5,
            color: 'black',
            fontSize: 9,
            backgroundColor: '#FDF05D',
            borderRadius: 10,
            padding: 5,
            marginBottom: 20,
          }}>
            Content Creator? Sign Up 
          </Text>
        </TouchableOpacity>
      )}
    </ScrollView>
  );
};

export default ViewTracker;
