import React, { useState, useEffect } from 'react';
import { SafeAreaView, StyleSheet, View, Text, ScrollView, Image, TouchableOpacity, Clipboard, TextInput, Button, Alert, Picker, Linking, Modal } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import QRCode from 'react-native-qrcode-svg';

const CollabCampaignDetails = ({ route, navigation }) => {
  const { CollabID } = route.params;
  const [secretQRID, setSecretQRID] = useState('');
  const [ipCount, setIpCount] = useState(null);
  const [tierData, setTierData] = useState(null);
  const [redeemableTier, setRedeemableTier] = useState('None');
  const [redeemableValue, setRedeemableValue] = useState(0);
  const [redeemableOffer, setRedeemableOffer] = useState(0);
  const [userData, setUserData] = useState({});
  const [brandEmail, setBrandEmail] = useState(null); 
  const [brandName, setBrandName] = useState(null);
  const [message, setMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedMinute, setSelectedMinute] = useState('');
  const [latestUpdate, setLatestUpdate] = useState({ Message: '', CreditAvailableStart: '', Status: '' });
  const [mediaContent, setMediaContent] = useState([]);
  const [apiMessages, setApiMessages] = useState([]);
  const [isRedeemed, setIsRedeemed] = useState(false);
  const [redeemDateTime, setRedeemDateTime] = useState(null);
  const [selectedTime, setSelectedTime] = useState('1:00 AM');
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
const [cancelMessage, setCancelMessage] = useState('');

const [shouldShowLogVisit, setShouldShowLogVisit] = useState(true);


const [isHowToCollabExpanded, setIsHowToCollabExpanded] = useState(false);

const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
const [updateMessage, setUpdateMessage] = useState('');

const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
const [approveMessage, setApproveMessage] = useState('');

const [activeTab, setActiveTab] = useState('CodeSystem'); // Default tab is CodeSystem


const [isExpanded, setIsExpanded] = useState(false);


const [venueRedeemStyle, setVenueRedeemStyle] = useState(null);

const [isCancelSuccessModalVisible, setIsCancelSuccessModalVisible] = useState(false);
const [cancelSuccessMessage, setCancelSuccessMessage] = useState('');


const fetchVenueRedeemSystem = (brandName) => {
  fetch(`https://collabbay.com/APIControllers/GetVenueRedeemStyle.php?BrandName=${brandName}`)
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        setVenueRedeemStyle(data.VenueRedeemSystem);
      } else {
        console.error('VenueRedeemSystem not found or API error:', data.message);
        setVenueRedeemStyle(null);
      }
    })
    .catch((error) => {
      console.error('Error fetching VenueRedeemSystem:', error);
      setVenueRedeemStyle(null);
    });
};


const handleToggleExpand = () => {
  setIsExpanded(!isExpanded);
};

  useEffect(() => {
    fetchSecretQRID();
    fetchNewApi();
    fetchUserData();
    fetchBrandDetails();
    fetchLatestCollabUpdate();
    fetchMediaContent();
    fetchUserMediaValidation();
    fetchBrandApprovalValidation();
    checkRedeemStatus();
  }, []);


  const fetchBrandDetails = () => {
    fetch(`https://collabbay.com/APIControllers/GetCollabBrandName.php?CollabID=${CollabID}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data[0] && data[0].BrandName) {
          const fetchedBrandName = data[0].BrandName;
          setBrandName(fetchedBrandName);
          fetchBrandEmail(fetchedBrandName);
          fetchVenueRedeemSystem(fetchedBrandName); // Fetch VenueRedeemSystem
        } else {
          console.error('Brand name not found');
        }
      })
      .catch((error) => console.error('Error fetching brand name:', error));
  };
  

 
  const handleCancelBooking = () => {
    const fullDateTime = `${selectedDate} ${selectedTime}`;
  
    fetch(`https://collabbay.com/APIControllers/UserCancelCollab.php?CollabID=${CollabID}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Message: cancelMessage,
        CreditAvailableStart: fullDateTime,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCancelSuccessMessage('Cancellation Sent Successfully.');
          setIsCancelSuccessModalVisible(true); // Show the modal
          setIsCancelModalVisible(false);       // Hide the main cancellation modal
        } else {
          setCancelSuccessMessage('There was an error processing your cancellation.');
          setIsCancelSuccessModalVisible(true);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setCancelSuccessMessage('An unexpected error occurred.');
        setIsCancelSuccessModalVisible(true);
      });
  };
  
  
  

  const toggleCancelModal = () => {
    setIsCancelModalVisible(!isCancelModalVisible);
  };
  
  const fetchBrandEmail = (brandName) => {
    fetch(`https://collabbay.com/APIControllers/GetCollabBrandEmail.php?BrandName=${brandName}`)
      .then(response => response.json())
      .then(data => {
        if (data && data[0] && data[0].UserEmail) {
          setBrandEmail(data[0].UserEmail);
        } else {
          console.error('Brand email not found');
        }
      })
      .catch(error => console.error('Error fetching brand email:', error));
  };

  const fetchSecretQRID = () => {
    fetch(`https://collabbay.com/APIControllers/RetrieveQRSecretForScan.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        if (data.SecretQRID) {
          setSecretQRID(data.SecretQRID);
        } else {
          console.error('SecretQRID not found');
        }
      })
      .catch(error => console.error('Error fetching SecretQRID:', error));
  };

  const fetchNewApi = () => {
    fetch(`https://collabBay.com/APIControllers/RetrieveCollabViewCount.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(responseData => {
        setIpCount(parseInt(responseData.ipCount));
        fetchTierAssignApi();
      })
      .catch(error => {
        console.error('Error fetching IP count:', error);
      });
  };

  const fetchTierAssignApi = () => {
    fetch(`https://collabbay.com/APIControllers/RetrieveCollabTierData.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(responseData => {
        setTierData(responseData);
      })
      .catch(error => {
        console.error('Error fetching tier data:', error);
      });  
  };

  const fetchUserData = () => {
    fetch(`https://collabbay.com/APIControllers/GetSessionData.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        setUserData(data);
      })
      .catch(error => {
        console.error('Error fetching user data: ', error);
      });
  };

  const fetchLatestCollabUpdate = () => {
    fetch(`https://collabbay.com/APIControllers/GetLatestCollabUpdate.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setLatestUpdate({
            Message: data.Message || '',
            CreditAvailableStart: data.CreditAvailableStart || '',
            Status: data.Status || '',
            Credits: data.Credits || '',
            BrandName: data.BrandName || '',
            NonCreditOffer: data.NonCreditOffer || '',
            AddedVisitors: data.AddedVisitors || 0,
            LastUpdateFrom: data.LastUpdateFrom || '', // Capture LastUpdateFrom
          });
        } else {
          console.error('Latest collab update not found');
        }
      })
      .catch(error => {
        console.error('Error fetching latest collab update:', error);
      });
  };
  
  
  const handleApproveCollab = () => {
    fetch(`https://collabbay.com/APIControllers/UserApproveCollab.php?CollabID=${CollabID}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionUsername: userData.sessionUsername, // Assuming sessionUsername is fetched
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.message === "Collab updated successfully.") {
          setApproveMessage('Booking accepted. The venue has been notified.');
          setLatestUpdate(prev => ({ ...prev, Status: 'Accepted' })); // Update status locally
        } else {
          setApproveMessage(data.message || 'Failed to approve collab.');
        }
        setIsApproveModalVisible(true); // Show the modal
      })
      .catch(error => {
        console.error('Error approving collab:', error);
        setApproveMessage('An unexpected error occurred.');
        setIsApproveModalVisible(true); // Show the modal
      });
  };
  

  const getCollabStatus = (collab) => {
    const today = new Date();
    const creditAvailableDate = new Date(collab.CreditAvailableStart);
  
    // If redeemed, prioritize the redeemed status
    if (isRedeemed) {
      return 'Redeemed';
    }
  
    if (collab.Status === 'Cancelled') {
      return 'Cancelled';
    }
  
    if (creditAvailableDate < today) {
      return 'Expired';
    }
  
    return collab.Status === 'Accepted' ? 'Upcoming' : 'Pending';
  };
  
  
  const getStatusStyle = (status) => {
    switch (status) {
      case 'Redeemed':
        return styles.redeemedStatus;
      case 'Expired':
        return styles.expiredStatus;
      case 'Upcoming':
        return styles.upcomingStatus;
      case 'Pending':
        return styles.pendingStatus;
      case 'Cancelled':
        return styles.cancelledStatus;
      default:
        return null;
    }
  };
  
  


  const fetchMediaContent = () => {
    fetch(`https://collabbay.com/APIControllers/GetCollabMedia.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0) {
          setMediaContent(data);
        } else {
          console.log('No media content found');
        }
      })
      .catch(error => {
        console.error('Error fetching media content:', error);
      });
  };



  const fetchUserMediaValidation = async () => {
    try {
        const response = await fetch(`https://collabbay.com/APIControllers/ErrorCheckUserMedia.php?CollabID=${CollabID}`);
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
        const result = await response.json(); // Parse as JSON

        const message = result.message === 'Not Validated' 
            ? "Warning. Pending content upload- submit your content below after your visit" 
            : "Content uploaded";
        
        setApiMessages(prev => [...prev, message]);
    } catch (error) {
        console.error('Error fetching user media validation:', error);
    }
};




  const fetchBrandApprovalValidation = async () => {
    try {
        const response = await fetch(`https://collabbay.com/APIControllers/ErrorCheckBrandApproval.php?CollabID=${CollabID}`);
        const result = await response.json(); // Parse as JSON

        const message = result.message === 'Not Validated' 
            ? "Warning, collab not approved by brand - offer not yet available"
            : "Collab approved by brand";
        
        setApiMessages(prev => [...prev, message]);
    } catch (error) {
        console.error('Error fetching brand approval validation:', error);
    }
};





const checkRedeemStatus = () => {
  fetch(`https://collabbay.com/APIControllers/CheckCollabRedeemedStatus.php?CollabID=${CollabID}`)
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        if (data.redeemed) {
          setIsRedeemed(true);
          setRedeemDateTime(data.redeemDateTime); // Store the redeem date
          setShouldShowLogVisit(false); // Block 'Log visit manually' option
        } else {
          setIsRedeemed(false);
          setShouldShowLogVisit(true);  // Allow 'Log visit manually'
        }
      } else {
        console.error('Error:', data.message);
      }
    })
    .catch((error) => console.error('Error fetching redeem status:', error));
};



const renderRedeemStatus = () => {
  if (isRedeemed) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginVertical: 10 }}>
        <Text style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>
          Redeemed on {new Date(redeemDateTime).toLocaleDateString('en-GB')} at{' '}
          {new Date(redeemDateTime).toLocaleTimeString('en-GB')}
        </Text>
      </View>
    );
  }
  return null; // If not redeemed, no need to display additional info since status covers it
};




  useEffect(() => {
    calculateRedeemableValues();
  }, [ipCount, tierData]);

  const calculateRedeemableValues = () => {
    if (ipCount !== null && tierData !== null) {
      if (ipCount >= 0 && ipCount < tierData.TierOneTarget) {
        setRedeemableTier('None');
        setRedeemableValue(0);
        setRedeemableOffer(0);
      } else if (ipCount >= tierData.TierOneTarget && ipCount < tierData.TierTwoTarget) {
        setRedeemableTier('1');
        setRedeemableValue(tierData.TierOneTarget);
        setRedeemableOffer(tierData.TierOneOffer);
      } else if (ipCount >= tierData.TierTwoTarget && ipCount < tierData.TierThreeTarget) {
        setRedeemableTier('2');
        setRedeemableValue(tierData.TierTwoTarget);
        setRedeemableOffer(tierData.TierTwoOffer);
      } else if (ipCount >= tierData.TierThreeTarget) {  
        setRedeemableTier('3');
        setRedeemableValue(tierData.TierThreeTarget);
        setRedeemableOffer(tierData.TierThreeOffer);
      }
    }
  };

  const handleCopyURL = () => {
    Clipboard.setString(`collabbay.com/collab/${CollabID}`);
    alert('Affiliate link copied to clipboard, share it with your audience.');
  };

  const handleBackPress = () => {
    navigation.goBack();
  };

  const navigateToMediaUpload = () => {
    Linking.openURL(`https://collabbay.com/UploadPost.html?CollabID=${CollabID}`);
  };

  const renderDatePicker = () => {
    const dates = [];
    const today = new Date();

    for (let i = 0; i < 90; i++) {
      const date = new Date();
      date.setDate(today.getDate() + i);
      dates.push(date.toDateString());
    }

    return (
      <View style={styles.datePickerContainer}>
        <Text style={styles.datePickerLabel}>Request New Date</Text>
        <ScrollView horizontal>
          {dates.map((date, index) => (
            <TouchableOpacity
              key={index}
              style={[styles.dateOption, { backgroundColor: selectedDate === date ? '#FDF05D' : 'white' }]}
              onPress={() => setSelectedDate(date)}
            >
              <Text style={styles.dateOptionText}>{date}</Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>
    );
  };
  
  const renderTimePicker = () => {
    return (
      <View style={styles.timePickerContainer}>
        <Text style={styles.timePickerLabel}>Select New Time</Text>
        <ScrollView style={styles.timeScrollView}>
          {Array.from({ length: 40 }, (_, i) => {
            const hour = Math.floor((i + 14) / 2) % 24; // Starts at 7:00 AM
            const minute = (i % 2) === 0 ? '00' : '30';
            const ampm = hour >= 12 ? 'PM' : 'AM';
            const adjustedHour = hour % 12 === 0 ? 12 : hour % 12; // Convert hour to 12-hour format
            const timeLabel = `${adjustedHour}:${minute} ${ampm}`;
  
            return (
              <TouchableOpacity
                key={i}
                style={[
                  styles.timeSlot,
                  selectedTime === timeLabel && styles.selectedTimeSlot,
                ]}
                onPress={() => setSelectedTime(timeLabel)}
              >
                <Text>{timeLabel}</Text>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </View>
    );
  };
  
  const handleSubmit = () => {
    const fullDateTime = `${selectedDate} ${selectedTime}`;
  
    fetch(`https://collabbay.com/APIControllers/UserUpdateCollab.php?CollabID=${CollabID}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Message: message,
        CreditAvailableStart: fullDateTime,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setUpdateMessage('Update submitted. The venue will need to accept this change before it is confirmed.');
          setIsUpdateModalVisible(true); // Show success modal
          setTimeout(() => {
            window.location.reload(); // Refresh after modal closes
          }, 4000);
        } else {
          setUpdateMessage('There was an error updating your collab details.');
          setIsUpdateModalVisible(true); // Show error modal
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setUpdateMessage('An unexpected error occurred.');
        setIsUpdateModalVisible(true); // Show error modal
      });
  };
  
  

  const renderMediaContent = () => {
    if (mediaContent.length === 0) {
      return (
        <View style={styles.noContentContainer}>
          <Text style={styles.noContentText}>Click 'Upload created content' to add your media.</Text>
        </View>
      );
    }
  
    return (
      <View style={styles.mediaContentSection}>
        {mediaContent.map((item, index) => (
          <View key={index} style={styles.mediaItemContainer}>
            <Image
              style={styles.mediaImage}
              source={{ uri: item.Image }}
              resizeMode="cover"
            />
            <View style={styles.mediaContentTextSection}>
              <Text style={styles.mediaPlatformText}>
                {item.PostPlatform}
              </Text>
              <TouchableOpacity
                onPress={() => {
                  const formattedURL = item.PostURL.startsWith('http')
                    ? item.PostURL
                    : `https://${item.PostURL}`;
                  Linking.openURL(formattedURL);
                }}
              >
                <Text style={styles.mediaURLText}>
                  <Ionicons name="link" size={20} color="blue" />
                  {item.PostURL}
                </Text>
              </TouchableOpacity>
              <Text style={styles.mediaMessageText}>
                <Ionicons name="chatbubble-ellipses" size={20} color="black" /> {item.Message}
              </Text>
            </View>
          </View>
        ))}
      </View>
    );
  };

  return (
    <SafeAreaView style={[styles.container, { width: '100%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 }]}>
  
<TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
          <Ionicons name="arrow-back" size={24} color="black" />
        </TouchableOpacity>


        {venueRedeemStyle && (
  <View style={[styles.venueCard, { marginTop: 10 }]}>
    <Ionicons name="notifications-outline" size={15} color="black" style={{ marginRight: 10 }} />
    <Text style={styles.venueCardText}>
      {venueRedeemStyle === 'QR'
        ? "This venue usually uses QR codes"
        : "This venue doesn't usually use QR codes"}
    </Text>
  </View>
)}

      <ScrollView contentContainerStyle={styles.contentContainer}>


      <TouchableOpacity
  style={{
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 5,
    elevation: 3,
    marginBottom: 20,
    width: '90%',
    alignSelf: 'center',
    backgroundColor: '#f3f3f3',
  }}
  onPress={() => setIsHowToCollabExpanded(!isHowToCollabExpanded)}
>
  <View>
    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
      <Text style={{ fontSize: 18, fontWeight: 'bold', color: '#333' }}>
        How to collab<Text style={{ fontWeight: 'bold', color: '#FDF05D' }}>•</Text>
      </Text>
      <Ionicons
        name={isHowToCollabExpanded ? 'chevron-up' : 'chevron-down'}
        size={24}
        color="black"
      />
    </View>

    {isHowToCollabExpanded && (
      <View style={{ marginTop: 5 }}>
        <View style={{ flexDirection: 'row', justifyContent: 'center', marginVertical: 10 }}>
          <TouchableOpacity
            style={{
              flex: 1,
              paddingVertical: 10,
              borderBottomWidth: 2,
              borderColor: activeTab === 'CodeSystem' ? 'black' : '#ccc',
              alignItems: 'center',
            }}
            onPress={() => setActiveTab('CodeSystem')}
          >
            <Text style={{ color: activeTab === 'CodeSystem' ? 'black' : '#777', fontWeight: 'bold' }}>
              Without your QR code
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              flex: 1,
              paddingVertical: 10,
              borderBottomWidth: 2,
              borderColor: activeTab === 'QRSystem' ? 'black' : '#ccc',
              alignItems: 'center',
            }}
            onPress={() => setActiveTab('QRSystem')}
          >
            <Text style={{ color: activeTab === 'QRSystem' ? 'black' : '#777', fontWeight: 'bold' }}>
              Using your QR code
            </Text>
          </TouchableOpacity>
        </View>

        {activeTab === 'CodeSystem' && (
          <View style={{ marginTop: 1 }}>
            <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
              <Text style={{ fontWeight: 'bold', color: '#000' }}>1-</Text> Arrive at the venue,
              mentioning you are with collabbay.
            </Text>
            <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
              <Text style={{ fontWeight: 'bold', color: '#000' }}>2-</Text> Ask the venue for their
              2-digit collabbay code, and log your visit below.
            </Text>
            <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
              <Text style={{ fontWeight: 'bold', color: '#000' }}>3-</Text> Enjoy the offer, post a
              story tagging the venue and collabbay, with your collab link below.
            </Text>
            <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
              <Text style={{ fontWeight: 'bold', color: '#000' }}>4-</Text> Start to record your
              content for your full-form post.
            </Text>
            <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
              <Text style={{ fontWeight: 'bold', color: '#000' }}>5-</Text> Final content is due
              within the agreed timeline after the visit.
            </Text>
          </View>
        )}

        {activeTab === 'QRSystem' && (
          <View style={{ marginTop: 1 }}>
            <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
              <Text style={{ fontWeight: 'bold', color: '#000' }}>1-</Text> Arrive at the venue,
              mentioning you are with collabbay.
            </Text>
            <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
              <Text style={{ fontWeight: 'bold', color: '#000' }}>2-</Text> Enjoy the offer, post a
              story tagging the venue and collabbay, with your collab link below.
            </Text>
            <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
              <Text style={{ fontWeight: 'bold', color: '#000' }}>3-</Text> Start to prepare your
              content for your full-form post.
            </Text>
            <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
              <Text style={{ fontWeight: 'bold', color: '#000' }}>4-</Text> Before you leave, ensure
              the brand scans your QR code.
            </Text>
            <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
              <Text style={{ fontWeight: 'bold', color: '#000' }}>5-</Text> Final content is due
              within the agreed timeline after the visit.
            </Text>
          </View>
        )}
      </View>
    )}
  </View>
</TouchableOpacity>



    <View style={[styles.statusBadge, getStatusStyle(getCollabStatus(latestUpdate))]}>
  <Text style={styles.statusText}>{getCollabStatus(latestUpdate)}</Text>
</View>

{latestUpdate.Status === 'Pending' &&
  latestUpdate.LastUpdateFrom &&
  latestUpdate.LastUpdateFrom !== userData.Username && (
    <View style={styles.approveButtonContainer}>
      <TouchableOpacity style={styles.approveButton} onPress={handleApproveCollab}>
        <Ionicons name="checkmark-circle" size={20} color="black" />
        <Text style={styles.approveButtonText}>Accept Collab</Text>
      </TouchableOpacity>
    </View>
)}


        <Text style={styles.creditsHeader}>{latestUpdate.NonCreditOffer}</Text>
        <Text style={styles.creditsHeaderSmall}>Value £{latestUpdate.Credits} at {latestUpdate.BrandName} </Text>

        <View style={styles.qrCodeContainer}>
          <QRCode
            value={`https://collabbay.com?type=PromoterScan&QRID=${secretQRID}`}
            size={200}
            color="black"
            backgroundColor="white"
          />
        </View>

        <Text style={{ textAlign: 'center', fontWeight: 'bold', color: 'black', marginTop: 5, marginBottom: 5 }}>
        or </Text>


        {shouldShowLogVisit && (
  <TouchableOpacity
    style={{
      borderRadius: 15,
      padding: 10,
      backgroundColor: '#fdf05d',
      marginBottom: 17,
      width: '70%',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    }}
    onPress={() => navigation.navigate('ManualVisitRedeem', { CollabID })}
  >
    <Text style={{ textAlign: 'center', fontSize: 18, marginLeft: 5, marginRight: 10, fontWeight: 'bold', color: 'black' }}>
      Log your visit manually
    </Text>
    <Ionicons name="arrow-forward" size={16} color="black" />
  </TouchableOpacity>
)}



 {renderRedeemStatus()}

        {/* <Text style={styles.emailCTA}>Contact Brand  {brandEmail ? brandEmail : ' '}</Text> */}

        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
  <TouchableOpacity
    style={{
      backgroundColor: 'black',
      padding: 10,
      borderRadius: 15,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '15',
    }}
    onPress={handleCopyURL}
  >
    <Text style={{ fontSize: 12, color: 'white' }}>
      Share{' '}collab link to your story <Ionicons name="copy-outline" size={16} />
    </Text>
  </TouchableOpacity>
</View>



        <View style={styles.statusCard}>
          <Text style={styles.subheader}>Collab Status</Text>
          <Text style={styles.statusLabel}>Message: {latestUpdate.Message}</Text>
          <Text style={styles.statusLabel}>Booking Date: {latestUpdate.CreditAvailableStart}</Text>
          <Text style={styles.statusLabel}>
           Status: {latestUpdate.Status === "Pending" ? "Pending. The booking has not yet been confirmed." : latestUpdate.Status}
          </Text>


          <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 8 }}>
  <Text style={styles.statusLabel}>
    Added Visitors (Plus-ones?): {latestUpdate.AddedVisitors}
  </Text>
  <TouchableOpacity onPress={() => setIsCancelModalVisible(true)}>
    <Ionicons name="help-circle-outline" size={20} color="darkgrey" style={{ marginLeft: 5 }} />
  </TouchableOpacity>
</View>

<Modal
  animationType="fade"
  transparent={true}
  visible={isApproveModalVisible}
  onRequestClose={() => {
    setIsApproveModalVisible(false);
    window.location.reload(); // Reload the page when the modal is closed
  }}
>
  <View style={styles.modalContainer}>
    <View style={styles.modalContent}>
      <Ionicons
        name={approveMessage.includes('accepted') ? "checkmark-circle-outline" : "alert-circle-outline"}
        size={48}
        color={approveMessage.includes('accepted') ? "green" : "red"}
      />
      <Text style={styles.modalText}>{approveMessage}</Text>
      <TouchableOpacity
        style={styles.modalButton}
        onPress={() => {
          setIsApproveModalVisible(false);
          window.location.reload(); // Reload the page when the button is clicked
        }}
      >
        <Text style={styles.modalButtonText}>Close</Text>
      </TouchableOpacity>
    </View>
  </View>
</Modal>



{isCancelModalVisible && (
  <View
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <View
      style={{
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 8,
        alignItems: 'center',
        width: '80%',
      }}
    >
      <Text style={{ fontSize: 16, textAlign: 'center', marginBottom: 15 }}>
        Via Collabbay, as the accpeted influencer, you can access this offer. Plus-ones are managed at the venues discretion.
      </Text>
      <TouchableOpacity
        onPress={() => setIsCancelModalVisible(false)}
        style={{
          backgroundColor: 'black',
          paddingVertical: 10,
          paddingHorizontal: 20,
          borderRadius: 5,
        }}
      >
        <Text style={{ color: 'white', fontSize: 16 }}>Close</Text>
      </TouchableOpacity>
    </View>
  </View>
)}

        </View>





<TouchableOpacity 
  style={[
    styles.header, 
    { backgroundColor: '#f3f3f3', padding: 7, borderRadius: 15, width: '80%',
      alignSelf: 'center' }
  ]} 
  onPress={handleToggleExpand}
>
  <Text style={styles.updateSubheader}>
    Move or cancel booking
    <Ionicons name={isExpanded ? 'chevron-up' : 'chevron-down'} size={24} color="black" />
  </Text>
</TouchableOpacity>

<Modal
  animationType="fade"
  transparent={true}
  visible={isUpdateModalVisible}
  onRequestClose={() => setIsUpdateModalVisible(false)}
>
  <View style={styles.modalContainer}>
    <View style={styles.modalContent}>
      <Ionicons
        name={updateMessage.includes('successfully') ? "checkmark-circle-outline" : "alert-circle-outline"}
        size={48}
        color={updateMessage.includes('successfully') ? "green" : "red"}
      />
      <Text style={styles.modalText}>{updateMessage}</Text>
      {!updateMessage.includes('Refreshing') && (
        <TouchableOpacity
          style={styles.modalButton}
          onPress={() => setIsUpdateModalVisible(false)}
        >
          <Text style={styles.modalButtonText}>Close</Text>
        </TouchableOpacity>
      )}
    </View>
  </View>
</Modal>


{isExpanded && (
  <View style={styles.updateCollabCard}>
    <Text style={styles.scanCTALabel}>Are you sure? Any updates require brand approval.</Text>
    {renderDatePicker()}
    {renderTimePicker()}
    <TextInput
      style={styles.textInput}
      placeholder="Add a message..."
      value={message}
      onChangeText={setMessage}
      maxLength={400}
      multiline={true}
    />
      <Text style={styles.charCounter}>
                {message.length} / 400
            </Text>
    <TouchableOpacity style={styles.button} onPress={handleSubmit}>
      <Text style={styles.buttonText}>Propose Update</Text>
    </TouchableOpacity>

    <TouchableOpacity style={[styles.button, { backgroundColor: '#' }]} onPress={toggleCancelModal}>
  <Ionicons name="warning-outline" size={20} color="red" />
  <Text style={{ color: 'red', textAlign: 'center'}}>Need to Cancel?</Text>
</TouchableOpacity>

{isCancelModalVisible && (
  <View style={styles.cancelModal}>
    <View style={styles.cancelModalContent}>
      <TouchableOpacity style={styles.closeButton} onPress={toggleCancelModal}>
        <Ionicons name="close-circle-outline" size={24} color="black" />
      </TouchableOpacity>
      <Text style={styles.cancelModalTitle}>Are you sure you want to cancel?</Text>
      <Text style={styles.cancelModalSubtitle}>Add a message (required):</Text>
      <TextInput
        style={styles.textInput}
        placeholder="Enter message..."
        value={cancelMessage}
        onChangeText={setCancelMessage}
        multiline={true}
        maxLength={400} // Set the character limit
      />
  <Text style={styles.charCounter}>
                {cancelMessage.length} / 400
            </Text>

      <View style={styles.cancelModalButtons}>
      <TouchableOpacity style={{ padding: 10 }} onPress={handleCancelBooking}>
  <Text style={{ color: 'black', fontSize: 14, textAlign: 'center', fontWeight: 'bold' }}>
    Yes, Cancel Booking
  </Text>
</TouchableOpacity>
<TouchableOpacity
  style={{
    backgroundColor: '#FDF05D',
    padding: 10,
    borderRadius: 20,
  }}
  onPress={toggleCancelModal}
>
  <Text style={{ color: 'black', fontSize: 14, textAlign: 'center', fontWeight: 'bold' }}>
    No, Go Back
  </Text>
</TouchableOpacity>
      </View>
    </View>
  </View>
)}


  </View>
)}


<Modal
  animationType="fade"
  transparent={true}
  visible={isCancelSuccessModalVisible}
  onRequestClose={() => setIsCancelSuccessModalVisible(false)}
>
  <View style={styles.modalContainer}>
    <View style={styles.modalContent}>
      <Ionicons
        name="checkmark-circle-outline"
        size={48}
        color="green"
      />
      <Text style={styles.modalText}>{cancelSuccessMessage}</Text>
      
      {/* Close Button */}
      <TouchableOpacity
        style={styles.modalButton}
        onPress={() => {
          setIsCancelSuccessModalVisible(false);  // Close the modal
          window.location.reload();               // Refresh the page
        }}
      >
        <Text style={styles.modalButtonText}>Close</Text>
      </TouchableOpacity>
    </View>
  </View>
</Modal>



        <View style={styles.apiMessagesContainer}>
      {apiMessages.map((msg, index) => (
        <View
          key={index}
          style={[
            styles.apiMessage,
            { backgroundColor: msg.includes('Warning') ? '#EC4646' : '#74dc8a' },
            { color: msg.includes('Warning') ? 'black' : 'black' }
          
          ]}
        >
          <Ionicons
            name={msg.includes('Warning') ? 'alert-circle' : 'checkmark-circle'}
            size={24}
            color="white"
            style={styles.icon}
          />
          <Text style={styles.apiMessageText}>{msg}</Text>
        </View>
      ))}
    </View>




<TouchableOpacity
  style={{
    backgroundColor: '#FDF05D',
    width: '70%',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10,
    borderRadius: 10,
  }}
  onPress={navigateToMediaUpload}
>
  <Text style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}>
    <Ionicons name="cloud-upload-outline" size={20} color="black" /> Upload created content
  </Text>
</TouchableOpacity>


        {renderMediaContent()}


      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: 'white',
    width: '95%',
    alignSelf: 'center', // Centers horizontally
    justifyContent: 'center', // Centers vertically
  },
  contentContainer: {
    paddingBottom: 32,
    paddingTop: 55,
  },

  header: {
    fontSize: 24,
    marginBottom: 16,
    textAlign: 'center',
  },
  creditsHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 3,
    textAlign: 'center',
  },
  creditsHeaderSmall: {
    fontSize: 20,
    marginBottom: 16,
    textAlign: 'center',
  },
  qrCodeContainer: {
    alignItems: 'center',
    marginBottom: 7,
  },
  label: {
    fontSize: 18,
    marginBottom: 8,
    color: 'black',
  },
  datePickerContainer: {
    marginBottom: 20,
  },
  datePickerLabel: {
    fontSize: 17,
    marginBottom: 8,
    marginTop: 18,
    fontWeight: 'bold',
    textAlign: 'left',
    color: 'black',
  },
  dateOption: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginRight: 8,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: 'black',
  },
  dateOptionText: {
    fontSize: 16,
    color: 'black',
  },
  timePickerContainer: {
    marginBottom: 16,
  },
  timePickerLabel: {
    fontSize: 18,
    marginBottom: 8,
    color: 'black',
    fontWeight: 'bold',
  },
  pickerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  pickerLabel: {
    fontSize: 16,
    marginRight: 8,
    color: 'black',
  },
  picker: {
    flex: 1,
    color: 'black',
  },
  textInput: {
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 4,
    padding: 8,
    marginBottom: 16,
    fontSize: 16,
    color: 'black',
  },
  scanCTALabel: {
    fontSize: 12,
    color: 'black',
    textAlign: 'center',
  },
  emailCTA: {
    fontSize: 10,
    color: 'black',
    textAlign: 'center',
  },
  statusCard: {
    backgroundColor: '#f3f3f3',
    padding: 16,
    marginVertical: 16,
    elevation: 2,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
  },
  updateCollabCard: {
    backgroundColor: '#F1F1F1',
    borderRadius: 8,
    borderColor: 'black',
    padding: 16,
    marginVertical: 16,
    elevation: 2,
    shadowColor: 'black',
  },
  subheader: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8,
    color: 'black',
  },
  updateSubheader: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
    color: 'black',
    marginLeft: 7,
    textAlign: 'center',
    padding: 2,
     },
  copyCollabButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    paddingVertical: 12,
    width: '80%',
    marginBottom: 16,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    borderRadius: 20,
    paddingVertical: 12,
    marginBottom: 16,

  },
  buttonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  copyButtonText: {
    fontSize: 12,
    color: 'black',
  },
  statusLabel: {
    fontSize: 18,
    marginBottom: 8,
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 20,
    color: 'black',
  },
  postButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'black',
  },
  mediaContentSection: {
    marginTop: 16,
    paddingHorizontal: 8,
    backgroundColor: 'none',
  },
  mediaContentHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    color: 'black',
    textAlign: 'center',
  },
  mediaItemContainer: {
    marginBottom: 24,
    borderRadius: 20,  
    padding: 16,
    elevation: 5,
  },
  mediaImage: {
    width: '100%',
    height: 500,
    borderRadius: 20,
    marginBottom: 16,
  },
  mediaContentTextSection: {
    padding: 16,
    borderRadius: 20,
    backgroundColor: '#F0F0F0',
    textAlign: 'center',
  },
  mediaPlatformText: {
    fontSize: 18,
    marginBottom: 8,
    color: 'black',
    textAlign: 'center',
  },
  mediaURLText: {
    fontSize: 16,
    color: 'blue',
    textDecorationLine: 'underline',
    marginBottom: 8,
    textAlign: 'center',
  },
  mediaMessageText: {
    fontSize: 16,
    color: 'black',
    textAlign: 'center',
  },
  apiMessagesContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  apiMessage: {
    borderRadius: 15,
    padding: 15,
    marginVertical: 5,
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  apiMessageText: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
    flex: 1,
  },
  icon: {
    marginRight: 10,
  },
  backButton: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
  },
   redeemedContainer: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    justifyContent: 'center',
    backgroundColor: "black",
    borderRadius: 15,
    width: '60%',
    alignItems: 'center',
    padding: 7,
    marginBottom: 5,

  },
  redeemedText: {
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  noContentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
    height: 300, // Adjust as per your design needs
  },
  noContentText: {
    color: 'gray',
    fontSize: 14,
    textAlign: 'center',
  },
  timeScrollView: {
    height: 120,
  },
  timeSlot: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: '#eee',
  },
  selectedTimeSlot: {
    backgroundColor: '#FDF05D',
  },
  cancelModal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelModalContent: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    width: '90%',
    alignItems: 'center',
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 5,
    elevation: 5,
  },
  cancelModalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#333',
    textAlign: 'center',
  },
  cancelModalSubtitle: {
    fontSize: 14,
    marginBottom: 10,
    color: '#666',
    textAlign: 'center',
  },
  cancelModalButtons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: 16,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  plusOneLabel: {
    fontSize: 12,
    marginBottom: 5,
    marginTop: 5,
    textAlign: 'center',
  },
  approveButtonContainer: {
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  marginVertical: 16,
},
approveButton: {
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#74dc8a',
  borderRadius: 20,
  paddingVertical: 12,
  paddingHorizontal: 24,
},
approveButtonText: {
  fontSize: 16,
  fontWeight: 'bold',
  color: 'black',
  marginLeft: 8,
},
expiredStatus: {
  color: 'black',
  backgroundColor: 'grey',
  paddingHorizontal: 10,
  paddingVertical: 5,
  borderRadius: 5,
},
redeemedStatus: {
  color: 'white',
  backgroundColor: 'green',
  paddingHorizontal: 10,
  paddingVertical: 5,
  borderRadius: 5,
},
upcomingStatus: {
  color: 'black',
  backgroundColor: 'lightgreen',
  paddingHorizontal: 10,
  paddingVertical: 5,
  borderRadius: 5,
},
pendingStatus: {
  color: 'black',
  backgroundColor: 'yellow',
  paddingHorizontal: 10,
  paddingVertical: 5,
  borderRadius: 5,
},
cancelledStatus: {
  backgroundColor: 'red',
  paddingHorizontal: 10,
  color: 'white',
  paddingVertical: 5,
  borderRadius: 5,
},
statusBadge: {
  position: 'absolute',
  top: 16,
  right: 23,
  borderRadius: 15,
  padding: 10,
},
statusText: {
  color: 'black',
  fontWeight: 'bold',
  fontSize: 17,
},
modalContainer: {
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
},
modalContent: {
  width: '80%',
  padding: 20,
  backgroundColor: 'white',
  borderRadius: 10,
  alignItems: 'center',
},
modalText: {
  fontSize: 18,
  textAlign: 'center',
  marginVertical: 10,
},
modalButton: {
  marginTop: 15,
  backgroundColor: '#FDF05D',
  padding: 10,
  borderRadius: 5,
},
modalButtonText: {
  fontSize: 16,
  fontWeight: 'bold',
  textAlign: 'center',
},
venueCard: {
  flexDirection: 'row',
  alignItems: 'right',
  justifyContent: 'center',
  padding: 10,
  borderRadius: 25,
  backgroundColor: '#f3f3f3',
  alignSelf: 'center',
  width: '40%',
  elevation: 2,
  shadowColor: 'black',
  shadowOpacity: 0.1,
  shadowRadius: 5,
},
venueCardText: {
  fontSize: 10,
  fontWeight: 'bold',
  color: '#333',
  textAlign: 'center',
},
charCounter: {
  marginTop: 3,
  marginBottom: 15,
  fontSize: 14,
  color: 'gray',
  textAlign: 'right',
},
modalContainer: {
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
},
modalContent: {
  width: '80%',
  padding: 20,
  backgroundColor: 'white',
  borderRadius: 10,
  alignItems: 'center',
  shadowColor: '#000',
  shadowOpacity: 0.2,
  shadowOffset: { width: 0, height: 2 },
  shadowRadius: 5,
  elevation: 5,
},
modalText: {
  fontSize: 18,
  fontWeight: 'bold',
  marginVertical: 10,
  textAlign: 'center',
  color: '#333',
},
modalButton: {
  marginTop: 15,
  backgroundColor: '#FDF05D',
  padding: 10,
  borderRadius: 5,
  width: '80%',
  alignItems: 'center',
},
modalButtonText: {
  fontSize: 16,
  fontWeight: 'bold',
  color: 'black',
},


});

export default CollabCampaignDetails;
